import React, { useContext, useEffect } from "react"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Icon from "../olc-framework/Icon"
import Col11 from "../grid/Col11"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import "./why-solar.scss"
import Section from "../configurable/Section"
import { GetModal } from "../configurable/Modal"
import "./bolds.scss"
import Col10 from "../grid/Col10"

import * as InfogWhyUs from "~/vectors/infog_why_us.inline.svg"
import * as InfogWhyUsBiz from "~/vectors/infog_why_us_biz.inline.svg"

const content = {
  pvArray: {
    title: 'Solar Panels',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6}} >      
        <div style={{lineHeight:1.2}}>
          <p>Either black or blue modules, made up cells, which collect sunlight and coverts it into Direct Current electricity, with the power output (electricity) transmitted along a cable to an inverter</p>
        </div>

        <div style={{display:'flex',flexDirection:'row', marginTop:12, marginBottom:16, alignSelf:'center'}}>
          <div>
            <Icon alias="sun" style={{color:' #3c96c5 ', fontSize:30}} />
          </div>
          <div style={{paddingLeft:8, display:'flex', alignSelf:'center' }}>
            <p style={{lineHeight:1.2}}>All our panels come with 25+ years warranty </p>
          </div>
        </div>
      </div>      
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a href='/panels-info'>Learn more</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  controller: {
    title: 'Optimiser',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6, marginBottom: 12}} >      
        <div style={{lineHeight:1.2}}>
          <p><strong>Location: </strong>inside property</p>
          <p>An optimiser is a DC-DC converter that is connected to a solar panel (or two panels for commercial installations), and it allows each panel to work individually with an inverter collecting the power output from each individual module.</p>
        </div>
      </div>      
      <div className="modal_btn_container" >
      <button className="modal_btn">
          <a href='/accessories-info#optimiser'>Learn more</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  battery: {
    title: 'Battery',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6}} >      
        <div style={{lineHeight:1.2}}>
          <p><strong>Location: </strong>inside or outside property</p>
          <p>Used to store the excess energy generated by the solar PV modules, which can be used at set times of the day or when these is no sunlight.</p>
        </div>
        <div style={{display:'flex',flexDirection:'row', marginTop:12, marginBottom:16, alignSelf:'center'}}>
          <div>
            <Icon alias="sun" style={{color:' #3c96c5 ', fontSize:30}} />
          </div>
          <div style={{paddingLeft:8, display:'flex', alignSelf:'center' }}>
            <p style={{lineHeight:1.2}}>All our batteries come with 10+ years warranty </p>
          </div>
        </div>
      </div>      
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a href='/batteries-info'>Learn more</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  inverter: {
    title: 'Inverter',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6}} >      
        <div style={{lineHeight:1.2}}>
          <p><strong>Location: </strong>inside property</p>
          <p>An inverter collects the Direct Current (DC) generated by the solar modules and then converts it into 240v single phase or 400v three-phase Alternating Current (AC) electricity, to be used in a home, business, commercial or industrial building and can be stored in a battery</p>
        </div>

        <div style={{display:'flex',flexDirection:'row', marginTop:12, marginBottom:16, alignSelf:'center'}}>
          <div>
            <Icon alias="sun" style={{color:' #3c96c5 ', fontSize:30}} />
          </div>
          <div style={{paddingLeft:8, display:'flex', alignSelf:'center' }}>
            <p style={{lineHeight:1.2}}>All our inverters come with 10+ years warranty </p>
          </div>
        </div>
      </div>      
      <div className="modal_btn_container">
      <button className="modal_btn">
          <a href='/inverters-info'>Learn more</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  grid: {
    title: 'National grid',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6, marginBottom: 12}} >      
        <div style={{lineHeight:1.2}}>
          <p>Energy is exported from grid during periods of large consumption which cannot be covered by the energy generated by the solar PV system or stored in the battery</p>
        </div>
      </div>      
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  EV: {
    title: 'EV Charger',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6}} >      
        <div style={{lineHeight:1.2}}>
          <p>EV charger or electric vehicle supply equipment (EVSE ), is a piece of equipment that supplies electrical power for charging plug-in electric vehicles. The installation consists of connecting the charge point to your electricity supply and installing it on your wall. For convenience, this will be located as close as possible to where you usually park your car. </p>
        </div>

        <div style={{display:'flex',flexDirection:'row', marginTop:12, marginBottom:16, alignSelf:'center'}}>
          <div>
            <Icon alias="sun" style={{color:' #3c96c5 ', fontSize:30}} />
          </div>
          <div style={{paddingLeft:8, display:'flex', alignSelf:'center' }}>
            <p style={{lineHeight:1.2}}>All our EV Chargers come with 5+ years warranty </p>
          </div>
        </div>
      </div>      
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a href='/accessories-info#ev'>Learn more</a>
        </button>
        <button className="modal_btn">
          <a  href='/contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  },
  property: {
    title: 'Your property',
    content: (
      <>     
      <div style={{marginLeft: 6, marginRight: 6, marginBottom: 12}} >      
        <div style={{lineHeight:1.2}}>
          <p>Your facilities will consume the solar energy generated by your PV system. We recommend a system designed around your property consumption in order to get the most out of your money. </p>
        </div>
      </div>      
      <div className="modal_btn_container">
        <button className="modal_btn">
          <a  href='/commercial-contact-us'>Ask a question</a>
        </button>
        <button className="modal_btn">
          <a href='/quote'>Get a quote</a>
        </button>
      </div>                  
      </>
    )
  }

}

const centerstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};


const WhySolar = ({ markdownNodes }) => {


  // START this makes it so the customer type is set dependng where the user came from

    const { customerType, setCustomerType } = useContext(CustomerTypeContext);

    const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
    const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
    const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);

  // END this makes it so the customer type is set dependng where the user came from

  //console.log('sarai',customerType)

 
  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000'; 
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);
  
  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  //END MODAL LOGIC

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        // setBrandFilter(brand);
        closeModal();        
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  return (
    <div>
      <GetModal
          show={Boolean(modalContent)}
          onClose={closeModal}
          title={getModalTitle()}
        >
          {getModalContent()}
      </GetModal>      
      
      <Hero imageUrl="/images/_breadcrumb21.png" compact>
        <Heading level={1} underlined>
        Why go Solar?
        </Heading>
      </Hero>
      <Block>
        <Section className="schematicSec" >
          <div className="container" style={{ marginLeft: 0 }}>
            <Col11>
              <Heading level={1} >How it Works</Heading>
            </Col11>
            <div className="row bold-formatting">
              <Col11>
              <p>
                There are <strong>multiple components</strong> in a solar PV system, with many brands to choose from. These brands can offer some very unique advantages and often make or break a system's ability to perform as expected. Photovoltaic systems generally consist of six individual components: the solar PV array, a charge controller, a battery bank, an inverter, a utility meter, and an electric grid. 
                </p>
                <p>
                However, a charge controller and battery bank are optional.
                </p>
                <p>Even though these two components help you store and make better use of your generated electricity, they could also increase the total price of the photovoltaic installation.
                </p>
              </Col11>
            </div>
          </div>
          <div className="row" >
            <Col10>
              <div className="infocontainer hidden-xs" >
                {isBusiness && (
                  <InfogWhyUsBiz/>
                )}
                {!isBusiness && (
                  <InfogWhyUs/>
                )}
                
                <button className="btn btn_array" onClick={() => onOpenModalFor('pvArray')}>Solar PV Array</button>
                <button className="btn btn_controller" onClick={() => onOpenModalFor('controller')}>Optimiser</button>
                <button className="btn btn_battery" onClick={() => onOpenModalFor('battery')}>Battery</button>
                <button className="btn btn_inverter" onClick={() => onOpenModalFor('inverter')}>Inverter</button>
                <button className="btn btn_grid" onClick={() => onOpenModalFor('grid')}>National Grid</button>
                <button className="btn btn_EV" onClick={() => onOpenModalFor('EV')}>EV Charger</button>
                <button className="btn btn_property" onClick={() => onOpenModalFor('property')}>Property</button>
              </div> 

              <div className="infocontainer visible-xs">
                {isBusiness && (
                  <InfogWhyUsBiz/>
                )}
                {!isBusiness && (
                  <InfogWhyUs/>
                )}
                <button style={{padding:'2px 5px'}} className="btn btn_array" onClick={() => onOpenModalFor('pvArray')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_controller" onClick={() => onOpenModalFor('controller')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_battery" onClick={() => onOpenModalFor('battery')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_inverter" onClick={() => onOpenModalFor('inverter')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_grid" onClick={() => onOpenModalFor('grid')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_EV" onClick={() => onOpenModalFor('EV')}>+</button>
                <button style={{padding:'2px 5px'}} className="btn btn_property" onClick={() => onOpenModalFor('property')}>+</button>
              </div>             
            </Col10>
          </div>
        </Section>

        <Section className="inverter-tech" >
          <div className="container" >
            <Col11>
                {isBusiness && (
                 <Heading level={2} underlined>The Right System for your Business</Heading>
                )}
                {!isBusiness && (
                 <Heading level={2} underlined>The Right System for your Home</Heading>
                )}
            </Col11>
            <div className="row bold-formatting">
              <Col11>
              <p>When it comes to a solar PV system it is not a case of one system fits all, our team of experts will work with each individual customer to understand energy consumption partners & usage, the property’s geographical location & orientation, roof type, roof angel and future renewable aspirations, such as battery storage and/or EV charging. This is done during the initial consultation, conducting a desktop design of the property, and followed up by site visits by one of our in-house experienced surveyors.</p>
              <p>Once we have gathered all the relevant information our team of designers will design the most effective system pulling for each customer using one of the market leading solar PV design software called PV*SOL®</p>
                {isBusiness && (
                  <>
                    <img className="visible-xs" src={require('../../images/why_solar/commercial_design.jpg')} style={{maxWidth:"80vw", margin:"auto", padding:50}} />
                    <img className="hidden-xs" src={require('../../images/why_solar/commercial_design.jpg')} style={{maxWidth:600, margin:"auto", padding:50}} />
                  </>
                )}
                {!isBusiness && (
                  <>
                    <img className="visible-xs" src={require('../../images/why_solar/domestic_design.jpg')} style={{maxWidth:"80vw", margin:"auto", padding:50}} />
                    <img className="hidden-xs" src={require('../../images/why_solar/domestic_design.jpg')} style={{maxWidth:600, margin:"auto", padding:50}} />
                  </>
                )}
              <p>Following on from the design a proposal will be created detailing the system that is being proposed, including generation details, CO2 savings, predicted future & annual savings, as well detailing the products that will make up your solar PV system. Our team of consultants will be on hand to walk you through your system and answer any questions you may have.</p>

              <p>Once you decide to go ahead with your system, we will manage each step of your solar journey organising the installation with our in-house team of installers right through to commissioning the system and training.</p>
              </Col11>
            </div>
          </div>
        </Section>

      </Block>
    </div>
  )
}

export default WhySolar
