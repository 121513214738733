import React from "react"
import Col, { ColProps } from "./Col"

const Col10: React.FC<ColProps> = (props) => <Col {
    ...{
      ...props,
      columns: 10,
    }} 
/>;

export default Col10